<template>
  <div class="not-found">
    <h1>Page Not Found</h1>
    <button
      class="btn-primary"
      v-on:click="goHome"
    >
      Home
    </button>
  </div>
</template>

<script scoped>
const title = 'Is it Babylon Bee? | Page Not Found';

export default {
  beforeRouteEnter(to, from, next) {
    document.title = title;
    next();
  },
  beforeRouteUpdate(to, from, next) {
    document.title = title;
    next();
  },
  methods: {
    goHome() {
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
  .not-found {
    display: flex;
    flex-direction: column;
  }
</style>
